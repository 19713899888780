import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const sqlCodeWithCopy = ({ text, type }) => {
    const [isCopied, setIsCopied] = useState(false)

    const handleCopy = () => {
        setIsCopied(true)
        setTimeout(function () {
            setIsCopied(false)
        }, 5000) // wait 5 seconds, then reset to false
    }
    return (
        <div className='p-relative'>
            <CopyToClipboard onCopy={() => handleCopy()} text={text}>
                <button
                    type='button'
                    id='copyButton'
                    aria-label='Copy to Clipboard Button'
                    className={`copy-button ${type === 'td' ? 'f-13' : 'f-18'}`}>
                    {isCopied ? <i className='fa fa-paste'></i> : <i className='fa fa-copy'></i>}
                </button>
            </CopyToClipboard>

            <SyntaxHighlighter
                language='sql'
                className={`my-0 py-0 rounded-0 h-100 ${type === 'td' ? 'mr-5' : 'z-index-1'} `}
                style={materialOceanic}>
                {text}
            </SyntaxHighlighter>
        </div>
    )
}

export default sqlCodeWithCopy

sqlCodeWithCopy.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
}
