import React from 'react'
import { graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import { v4 as uuidv4 } from 'uuid'

import Layout from '../../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import SqlCodeWithCopy from '../../components/SqlCodeWithCopy'
import get from 'lodash/get'

interface HeadingStateProps {
  activeHeadingId: any
  headingsTwo: any
  clickedToc: any
}

class sqlCheatSheet extends React.Component<{}, HeadingStateProps> {
  constructor(props) {
    super(props)
    this.state = {
      activeHeadingId: null,
      headingsTwo: [],
      clickedToc: false,
    }
  }

  componentDidMount() {
    let h2s = document.querySelectorAll<HTMLElement>('.heading-toc')
    let arrayOfH2sText = []
    let h2sArray = Array.prototype.slice.call(h2s)
    // Dynamically allocating ID's to H2
    // And getting text from h2 elements
    h2sArray.map((h2, index) => {
      h2.id = 'heading' + index
      arrayOfH2sText.push(h2.innerText)
    })

    function isInViewport(element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      )
    }

    const handleActiveHeader = () => {
      // Checking here to disable auto selecting the next one
      // If more than one in viewport when user selects one
      if (!this.state.clickedToc) {
        let h2s = document.querySelectorAll<HTMLElement>('.heading-toc')
        let activeh2
        // Loop through headings
        for (const h2 of h2s) {
          // Check if element is further than we've scrolled
          if (h2.getBoundingClientRect().top > 0) {
            // Check if element is in viewport
            if (isInViewport(h2)) {
              activeh2 = h2.id
            }
          }
        }
        if (activeh2) {
          this.setState({
            ...this.state,
            activeHeadingId: activeh2,
          })
        }
      } else {
        this.setState({
          ...this.state,
          clickedToc: false,
        })
      }
    }

    // Triggering active header
    window.addEventListener('scroll', handleActiveHeader)

    // Setting state with the h2 element text
    // Setting showTOC based on length of blog main body content
    this.setState({
      ...this.state,
      headingsTwo: arrayOfH2sText,
    })

    const handleVisibility = () => {
      if (window.screen.width > 1200) {
        if (
          document.querySelector('.footer-cta-section').getBoundingClientRect()
            .top -
            500 <
          0
        ) {
          document
            .querySelector('.toc-table-container')
            ?.classList.remove('d-xl-block')
        } else {
          document
            .querySelector('.toc-table-container')
            ?.classList.add('d-xl-block')
        }
      }
    }
    window.onscroll = () => {
      handleVisibility()
    }
  }
  render() {
    const pageSEO = get(this.props, 'data.allContentfulPages.edges[0].node')

    const headingClicked = (e) => {
      // Used to restore initial position in mobile/lg screens
      document.querySelector('#collapseTOC').classList.remove('show')
      document.querySelector<HTMLElement>('#tocTrigger').innerText =
        'Table of Contents →'

      this.setState({
        ...this.state,
        activeHeadingId: 'heading' + e.target.id,
        clickedToc: true,
      })
    }

    const handleCollapse = (e) => {
      if (e.target.innerText === 'Table of Contents →') {
        e.target.innerText = 'Table of Contents ↑'
      } else {
        e.target.innerText = 'Table of Contents →'
      }
    }

    return (
      <Layout newsletter={false}>
        <HeaderSEO pageSEO={pageSEO} />

        {/* <Progress /> */}
        <div className="container-1 hero-margin">
          <div className="d-flex flex-wrap justify-content-center mobile-padding-top-half">
            <div className="col-lg-9 col-md-12 col-12 blog-content-parent ">
              <h1 className="lh-2-3 text-center">
                Streaming SQL Cheat Sheet for Apache Kafka
              </h1>
              <div className="w-75 mx-auto mt-4">
                <h2 className="text-center my-2 fw-400 f-20 lh-32">
                  A low code way for engineers to learn stream processing.
                </h2>
              </div>
              <div className="mt-5">
                <StaticImage
                  className="img-fluid"
                  src="../../resources/images/sql_cheatsheet/hero-image.png"
                  placeholder="tracedSVG"
                  alt="SQL Cheatsheet"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-1">
          <div className="mt-3">
            <div className="d-flex flex-wrap justify-content-center">
              {/* For XL screens */}

              <div className="p-relative">
                <div className="toc-table-container toc-table-container-scroll d-xl-block d-none">
                  <div className="toc-table rounded-1 shadow-sm">
                    <div className="footer-color rounded p-4">
                      <ul className="list-unstyled">
                        {this.state.headingsTwo &&
                          this.state.headingsTwo.map((heading2, index) => {
                            return (
                              <li key={uuidv4()}>
                                <a
                                  id={`${index}`}
                                  className={`toc-header f-16 roboto fw-700 ${
                                    this.state.activeHeadingId ==
                                    'heading' + index
                                      ? 'active-heading'
                                      : ''
                                  } `}
                                  href={`#heading${index}`}
                                  onClick={(e) => headingClicked(e)}
                                >
                                  {heading2}
                                </a>
                              </li>
                            )
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* For mobile to large screens */}

              <div className="d-flex flex-wrap justify-content-center">
                <div className="toc-table-container-lg toc-table-container-scroll d-xl-none d-block z-index-999">
                  <div className="collapse" id="collapseTOC">
                    <div className="toc-table-lg rounded-1 shadow-sm">
                      <div className="footer-color rounded p-4">
                        <ul className="list-unstyled">
                          {this.state.headingsTwo &&
                            this.state.headingsTwo.map((heading2, index) => {
                              return (
                                <li key={uuidv4()}>
                                  <a
                                    id={`${index}`}
                                    className={`toc-header f-16 roboto fw-700 ${
                                      this.state.activeHeadingId ==
                                      'heading' + index
                                        ? 'active-heading'
                                        : ''
                                    } `}
                                    href={`#heading${index}`}
                                    onClick={(e) => headingClicked(e)}
                                  >
                                    {heading2}
                                  </a>
                                </li>
                              )
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 footer-color p-3 border-0">
                    <a
                      className="text-left d-block"
                      data-toggle="collapse"
                      href="#collapseTOC"
                      onClick={(e) => handleCollapse(e)}
                      id="tocTrigger"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseTOC"
                    >
                      Table of Contents &rarr;
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="blog-body-container
                   offset-0 offset-lg-3 col-lg-9 col-md-12 col-12 border-top mt-3 pt-3"
              >
                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  What is Lenses?
                </h2>

                <p>
                  Lenses is developer experience for building and operating
                  streaming applications on open-source technologies. Deployed
                  as a container or as a JVM application, Lenses.io works with
                  any Kafka & Kubernetes environment including managed and cloud
                  services.
                </p>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Lenses Streaming SQL
                </h2>

                <p>
                  Streaming SQL allows anyone to build stream processing
                  applications with SQL. Queries react to data as soon as it is
                  available in <b> Apache Kafka</b>. They can reshape your data,
                  aggregate it based on any field or time window, or enrich it
                  with other streaming data. The results are pushed back to
                  Kafka topics so that downstream applications and processes can
                  consume them. Pipelines are deployed and scaled on your own
                  Kubernetes environment or managed cloud solutions.
                </p>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Try out Streaming SQL
                </h2>

                <div className="text-center roll-button pt-4">
                  <a className="bg-light-blue text-white" href="/start/">
                    <span data-hover="Get Lenses free"> Get Lenses free</span>
                  </a>
                </div>
                <StaticImage
                  className="img-fluid mx-auto d-block "
                  src="../../resources/images/sql_cheatsheet/diagram.svg"
                  placeholder="tracedSVG"
                  alt="Lenses Streaming SQL"
                />

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Stateful and Stateless processing
                </h2>

                <p>
                  Streams (Stateless) and tables (Stateful) have different
                  semantics and use-cases, but are strongly related nonetheless.
                </p>

                <p>
                  This relationship is known as <i> stream-table</i> duality.
                  Every stream can be interpreted as a table, and similarly a
                  table can be interpreted as a stream.
                </p>

                <table className="table table-responsive sql-cheatsheet-table">
                  <tbody className="border-pricing-table bordered-td">
                    <tr>
                      <td className="align-middle">
                        <span className="text-uppercase fw-600">Stream </span>
                        (Stateless)
                      </td>
                      <td className="align-middle">
                        Represents an unbounded sequence of independent events
                        over a continuously changing dataset. The dataset is the
                        totality of all data described by every event received
                        so far
                      </td>
                      <td className="bg-sql-td-dark">
                        <SqlCodeWithCopy
                          text={'SELECT STREAM *\nFROM website_traffic'}
                          type={'td'}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="text-uppercase fw-600">Table </span>
                        (Stateful)
                      </td>
                      <td className="align-middle">
                        For each key, a table holds the latest version (state)
                        received of its value. Upon receiving events for keys
                        that already have an associated value, such values will
                        be overridden and state changed
                      </td>
                      <td className="bg-sql-td-dark">
                        <SqlCodeWithCopy
                          text={'SELECT TABLE *\nFROM customers'}
                          type={'td'}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Streaming SQL example use cases
                </h2>

                <table className="table table-responsive sql-cheatsheet-table bordered-td">
                  <thead>
                    <tr>
                      <td>Use case</td>
                      <td>Description</td>
                    </tr>
                  </thead>
                  <tbody className="border-pricing-table">
                    <tr>
                      <td className="align-middle">Filtering</td>
                      <td className="align-middle">
                        Read payment events in topic cc_payments and filter
                        those with value greater than 5000$ into
                        large_cc_payments topic for possible fraud.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">Aggregating</td>
                      <td className="align-middle">
                        In a topic containing information about network traffic,
                        average the bandwidth per network device in a rolling 5
                        minute window.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">Enriching</td>
                      <td className="align-middle">
                        For a topic containing customers orders, join the events
                        with customer information in a customer&apos;s topic to
                        a new topic.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">Reshaping</td>
                      <td className="align-middle">
                        Unwrap certain field values for a topic containing
                        energy usage information into another topic so that it
                        can be sent to a time-series database.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">Re-key</td>
                      <td className="align-middle">
                        Change the event key to suit a downstream consumer or
                        align the topic for Kafka Streams joins/aggregation
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">Reformatting</td>
                      <td className="align-middle">
                        Translate incoming JSON data to AVRO for better control
                        over schema evolution, rogue messages and so on.
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Supported formats
                </h2>

                <p>
                  Lenses.io supports reading and writing data from/to Kafka
                  topics in different serialisation formats.
                </p>

                <div className="d-flex flex-wrap justify-content-center">
                  <div className="col-auto">
                    <table className="table table-responsive sql-cheatsheet-table border-left bordered-td">
                      <thead>
                        <tr>
                          <td>Format</td>
                          <td>Read</td>
                          <td>Write</td>
                        </tr>
                      </thead>
                      <tbody className="border-pricing-table">
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Int
                          </td>
                          <td className="align-middle">yes</td>
                          <td className="align-middle">yes</td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Bytes
                          </td>
                          <td className="align-middle">yes</td>
                          <td className="align-middle">yes</td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Long
                          </td>
                          <td className="align-middle">yes</td>
                          <td className="align-middle">yes</td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            String
                          </td>
                          <td className="align-middle">yes</td>
                          <td className="align-middle">yes</td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Json
                          </td>
                          <td className="align-middle">yes</td>
                          <td className="align-middle">yes</td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Avro
                          </td>
                          <td className="align-middle">Via schema registry</td>
                          <td className="align-middle">Via schema registry</td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            XML
                          </td>
                          <td className="align-middle">yes</td>
                          <td className="align-middle">yes</td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Csv
                          </td>
                          <td className="align-middle">yes</td>
                          <td className="align-middle">not yet</td>
                        </tr>
                        <tr>
                          <td className="align-middle fw-600">
                            Google Protobuf
                          </td>
                          <td className="align-middle">
                            Via custom configuration
                          </td>
                          <td className="align-middle">not yet</td>
                        </tr>
                        <tr>
                          <td className="align-middle fw-600">Custom</td>
                          <td className="align-middle">
                            Via custom configuration
                          </td>
                          <td className="align-middle">no</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  First basic example
                </h2>

                <SqlCodeWithCopy
                  text={
                    '\nSET defaults.topic.autocreate=true;\n\nINSERT INTO speeding_cars\nSELECT STREAM\n\tcar_speed as speed\n\t , car_name\nWHERE car_speed > 100\nFROM car_data;\n'
                  }
                />

                <p className="mt-4">
                  The above example will auto-create any necessary topics (in
                  this case, “speeding_cars” topic) and populate it with the
                  value of car_speed renamed as speed and car_name from the
                  car_data topic only for events where the car_speed is greater
                  than 100.
                </p>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Kafka Record/Message
                </h2>
                <div className="d-flex flex-wrap justify-content-center">
                  <div className="col-auto">
                    <table className="table table-responsive sql-cheatsheet-table bordered-td">
                      <thead>
                        <tr>
                          <td>Facet</td>
                          <td>Lenses.io reference</td>
                          <td>Example</td>
                        </tr>
                      </thead>
                      <tbody className="border-pricing-table">
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Key
                          </td>
                          <td className="align-middle">
                            _key <br /> <br /> OR <br /> <br />{' '}
                            _key.&lt;fieldname&gt;
                          </td>
                          <td className="bg-sql-td-dark">
                            <SqlCodeWithCopy
                              text={
                                'INSERT INTO outputTopic \nSELECT STREAM _key as customerId\nFROM customers'
                              }
                              type={'td'}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Value
                          </td>
                          <td className="align-middle">
                            _value <br />
                            <br /> OR <br />
                            <br /> _value.&lt;fieldname&gt;
                            <br />
                            <br /> OR
                            <br />
                            <br />
                            &lt;fieldname&gt;
                            <br />
                            <br />
                            OR *
                          </td>
                          <td className="bg-sql-td-dark">
                            <SqlCodeWithCopy
                              text={
                                'INSERT INTO outputTopic\nSELECT STREAM _value.firstName \nFROM customers'
                              }
                              type={'td'}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Projections
                </h2>

                <p>
                  A projection represents the ability to project a given input
                  value onto a target location (key or value) in an output
                  record. Projections are the main building block of{' '}
                  <code> SELECT </code>
                  statements.
                </p>

                <SqlCodeWithCopy
                  text={
                    "\nINSERT INTO target-topic\nSELECT STREAM\n\tCONCAT('a', 'b') AS result1\n\t, (1 + field1) AS _key.a\n\t, _key.field2 AS result3\n\t, CASE\n\t\tWHEN field3 = 'Robert' THEN 'It`s bobby'\n\t\tWHEN field3 ='William' THEN 'It`s willy'\n\t\tELSE 'Unknown'\n\t\tEND AS who_is_it\nFROM input-topic;\n"
                  }
                />

                <p className="mt-4">
                  In the above example, <code> result1, _key.a, result3 </code>{' '}
                  and <code> who_is_it</code>
                  are all fields outputted into target-topic.
                </p>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Operators & Expressions
                </h2>
                <div className="d-flex flex-wrap justify-content-center">
                  <div className="col-auto">
                    <table className="table table-responsive sql-cheatsheet-table bordered-td">
                      <thead>
                        <tr>
                          <td>Type</td>
                          <td>Supported Operators</td>
                          <td>Example</td>
                        </tr>
                      </thead>
                      <tbody className="border-pricing-table">
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Logical
                          </td>
                          <td className="align-middle">AND, OR</td>
                          <td className="bg-sql-td-dark">
                            <SqlCodeWithCopy
                              text={
                                'WHERE 1 + _value.field1 > 5 \nAND value.field2 < 10'
                              }
                              type={'td'}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Arithmetic
                          </td>
                          <td className="align-middle">+,-,*,/,%(mod)</td>
                          <td className="bg-sql-td-dark">
                            <SqlCodeWithCopy
                              text={'WHERE 1 + _value.field1 \n> 5'}
                              type={'td'}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Ordering
                          </td>
                          <td className="align-middle">
                            &gt;,&gt;=,&lt;,&lt;=
                          </td>
                          <td className="bg-sql-td-dark">
                            <SqlCodeWithCopy
                              text={'WHERE _value.field1 \n> _value.field2'}
                              type={'td'}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Equality
                          </td>
                          <td className="align-middle">=, !=</td>
                          <td className="bg-sql-td-dark">
                            <SqlCodeWithCopy
                              text={'WHERE _key !=\nLENGTH(_value.field1)'}
                              type={'td'}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            String
                          </td>
                          <td className="align-middle">LIKE, NOT LIKE</td>
                          <td className="bg-sql-td-dark">
                            <SqlCodeWithCopy
                              text={'WHERE _value.field1 \nNOT LIKE “%foo%”'}
                              type={'td'}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            CASE
                          </td>
                          <td className="align-middle">IN </td>

                          <td className="bg-sql-td-dark">
                            <SqlCodeWithCopy
                              text={
                                "CASE WHEN field3 = 'Robert' \nTHEN 'Its bobby' \nWHEN field3 = 'William'\nTHEN 'Its willy'\nELSE 'Unknown'\nEND AS who_is_it"
                              }
                              type={'td'}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Examples
                </h2>

                <p>
                  Select <code> move_number </code> and{' '}
                  <code> date_released</code> from
                  <code> transformer_movies_xml</code> topic and put in as AVRO
                  format in
                  <code> transformer_movies_avro </code> topic. Make{' '}
                  <code> movie_number</code> as the _key and only select movies
                  where <code> movie_number</code> is less than 300.
                </p>

                <SqlCodeWithCopy
                  text={
                    '\nINSERT INTO transformer_movies_avro\n\tSTORE KEY AS AVRO\n\tVALUE AS AVRO\n\tSELECT STREAM movie_number AS _key\n\t, date_released\n\tFROM transformer_movies_xml\n\tWHERE movie_number > 0 AND\n\tmovie_number < 300\n'
                  }
                />

                <StaticImage
                  className="img-fluid"
                  src="../../resources/images/sql_cheatsheet/example-01.svg"
                  placeholder="tracedSVG"
                  alt="example1"
                />

                <hr />

                <p className="py-3">
                  Calculate the rolling average KW for each{' '}
                  <code>customer_id</code> for every 10 minute period every 5
                  minutes. Populate into
                  <code>electricity_event_avg</code> stream
                </p>
                <SqlCodeWithCopy
                  text={
                    '\nINSERT INTO electricity_events_avg\n\tSELECT STREAM customer_id\n\t, AVG (KW) AS KW\n\tFROM electricity_events\n\tWINDOW BY HOP 10m, 5m\n\tGROUP BY customer_id\n'
                  }
                />
                <div className="my-4"></div>
                <StaticImage
                  className="img-fluid"
                  src="../../resources/images/sql_cheatsheet/example-02.svg"
                  placeholder="tracedSVG"
                  alt="example2"
                />

                <hr />

                <p className="py-3">
                  Populate the <code>ship_speeds_with_names</code> topic with
                  the speed field from <code>fast_vessel_processor</code> topic
                  joined with the <code>ship_names</code> topic using the MMSI
                  value. The <code>ship_names</code> MMSI value is stored as a
                  STRING whereas the <code>fast_vessel_processor</code> is
                  stored as a LONG. The <code>ship_names</code> also
                  doesn&apos;t have a key so needs to be rekeyed (
                  <code>ship_names_rekeyed</code>) in order to build a state
                  table (<code>ship_names_state</code>) since states cannot have
                  NULL keys.
                </p>
                <SqlCodeWithCopy
                  text={
                    '\nWITH ship_names_rekeyed AS\n\t(SELECT STREAM ship_names.mmsi AS _key\n\tFROM ship_names);\n\tWITH ship_names_state AS\n\t(SELECT TABLE *\n\tFROM ship_names_rekeyed);\n\tINSERT INTO ship_speeds_with_names\n\tSELECT STREAM fast_vessel_processor._key AS mmsikey\n\t, fast_vessel_processor.Speed\n\t, ship_names.owner\n\t, ship_names.name\n\tFROM fast_vessel_processor\n\tINNER JOIN ship_names_state ON fast_vessel_processor._key.MMSI\n\t= CAST(ship_names_state._key AS LONG);\n'
                  }
                />

                <div className="my-4"></div>
                <StaticImage
                  className="img-fluid"
                  src="../../resources/images/sql_cheatsheet/example-03.svg"
                  placeholder="tracedSVG"
                  alt="example3"
                />

                <hr />
                <p className="py-3">
                  Reshape the <code>car_speed_events</code> stream by setting{' '}
                  <code>sensor_id</code> and
                  <code>event_time</code> as keys and nesting mph and a
                  calculated kmph values for speed object in the value field.
                </p>
                <SqlCodeWithCopy
                  text={
                    '\nSELECT STREAM sensor.id AS _key.sensor_id\n\t, event_time AS _key.event_time\n\t, car_id\n\t, speedMph AS speed.mph\n\t, speedMph * 1.60934 AS speed.kmph\n\tFROM car_speed_events;\n'
                  }
                />

                <div className="my-4"></div>

                <StaticImage
                  className="img-fluid"
                  src="../../resources/images/sql_cheatsheet/example-04.svg"
                  placeholder="tracedSVG"
                  alt="example4"
                />

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Changing Storage Format
                </h2>

                <p>
                  At times, it is useful to control the resulting Key and/or
                  Value storage of the output topic. If the input is Json, the
                  output for the streaming computation can be set to Avro.
                </p>

                <p>The syntax is the following:</p>

                <SqlCodeWithCopy
                  text={
                    '\nINSERT INTO <target topic>\n\tSTORE \n\tKEY AS <format>\n\tVALUE AS <format>\n...\n'
                  }
                />

                <div className="mt-5"></div>
                <table className="table table-responsive sql-cheatsheet-table bordered-td">
                  <thead>
                    <tr>
                      <td>From \ To</td>
                      <td>INT</td>
                      <td>LONG</td>
                      <td>STRING</td>
                      <td>JSON</td>
                      <td>AVRO</td>
                    </tr>
                  </thead>
                  <tbody className="border-pricing-table">
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        INT
                      </td>
                      <td className="align-middle">=</td>
                      <td className="align-middle">yes</td>
                      <td className="align-middle">yes</td>
                      <td className="align-middle">no</td>
                      <td className="align-middle">yes</td>
                    </tr>

                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        LONG
                      </td>
                      <td className="align-middle">no</td>
                      <td className="align-middle">=</td>
                      <td className="align-middle">yes</td>
                      <td className="align-middle">no</td>
                      <td className="align-middle">yes</td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        String
                      </td>
                      <td className="align-middle">no</td>
                      <td className="align-middle">no</td>
                      <td className="align-middle">=</td>
                      <td className="align-middle">no</td>
                      <td className="align-middle">yes</td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        Json
                      </td>
                      <td className="align-middle">
                        If the Json storage contains integer only
                      </td>
                      <td className="align-middle">
                        If the Json storage contains integer or long only
                      </td>
                      <td className="align-middle">yes</td>
                      <td className="align-middle">=</td>
                      <td className="align-middle">yes</td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        Avro
                      </td>
                      <td className="align-middle">
                        If Avro storage contains integer only
                      </td>
                      <td className="align-middle">
                        If the Avro storage contains integer or long only
                      </td>
                      <td className="align-middle">yes</td>
                      <td className="align-middle">yes</td>
                      <td className="align-middle">=</td>
                    </tr>
                  </tbody>
                </table>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  JOINS
                </h2>

                <h3 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Join Types
                </h3>

                <table className="table table-responsive sql-cheatsheet-table bordered-td">
                  <thead>
                    <tr>
                      <td>Join Type</td>
                      <td>Description</td>
                      <td>Lexicon</td>
                      <td>Example</td>
                    </tr>
                  </thead>
                  <tbody className="border-pricing-table">
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        Inner
                      </td>
                      <td className="align-middle">
                        This join type will only emit records where a match has
                        occurred.
                      </td>
                      <td className="align-middle">JOIN</td>
                      <td className="bg-sql-td-dark">
                        <SqlCodeWithCopy
                          text={
                            'INSERT INTO result \nSELECT STREAM customersTable.name\n, ordersStream.item \nFROM ordersStream \nJOIN customersTable\nON customersTable.id =\nordersStream.customer_id;'
                          }
                          type={'td'}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        Left
                      </td>
                      <td className="align-middle">
                        Selects all the records from the left side of the join
                        regardless of a match.
                      </td>
                      <td className="align-middle">LEFT JOIN</td>
                      <td className="bg-sql-td-dark">
                        <SqlCodeWithCopy
                          text={
                            'INSERT INTO result \nSELECT STREAM customersTable.name\n, ordersStream.item \nFROM ordersStream \nLEFT JOIN customersTable\nON customersTable.id =\nordersStream.customer_id;'
                          }
                          type={'td'}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        Right
                      </td>
                      <td className="align-middle">
                        A mirror of a LEFT JOIN. It selects all the records from
                        the right side of the join regardless of a match.
                      </td>
                      <td className="align-middle">RIGHT JOIN</td>
                      <td className="bg-sql-td-dark">
                        <SqlCodeWithCopy
                          text={
                            'INSERT INTO result \nSELECT TABLE customersTable.name\n, ordersStream.item \nFROM customersTable \nRIGHT JOIN ordersStream\nON customersTable.id =\nordersStream.customer_id;'
                          }
                          type={'td'}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        Outer
                      </td>
                      <td className="align-middle">
                        Union of left and right joins. It selects all records
                        from the left and right side of the join regardless of a
                        match happening.
                      </td>
                      <td className="align-middle">OUTER</td>
                      <td className="bg-sql-td-dark">
                        <SqlCodeWithCopy
                          text={
                            'INSERT INTO result \nSELECT TABLE customersStream.name\n, ordersStream.item \nFROM ordersStream \nOUTER JOIN customersStream\nON customersTable.id =\nordersStream.customer_id;'
                          }
                          type={'td'}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h3 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Join Match Expressions
                </h3>

                <p>
                  if no ON expression is provided, the join will be evaluated
                  based on the equality of the _key facet
                </p>

                <table className="table table-responsive sql-cheatsheet-table">
                  <tbody className="border-pricing-table bordered-td">
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        Equality
                      </td>
                      <td className="align-middle">
                        customers.id = order.user_id <br /> <br /> customers.id
                        - 1 = order.user_id - 1 <br /> <br />{' '}
                        substr(customers.name, 5) = order.item
                      </td>

                      <td className="bg-sql-td-dark">
                        <SqlCodeWithCopy
                          text={
                            'INSERT INTO RESULT \nSELECT TABLE customersStream.name\n, ordersStream.item \nFROM ordersStream \nOUTER JOIN customersStream\nON substr(customersTable.name, 5) =\nordersStream.customerName;'
                          }
                          type={'td'}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        Boolean
                      </td>
                      <td className="align-middle">
                        len(customers.name) &gt; 10 <br /> <br />{' '}
                        substr(customer.name,1) = &ldquo;J&rdquo;
                        <br /> <br /> len(customer.name) &gt; 10 OR customer_key
                        &gt; 1
                      </td>
                      <td className="bg-sql-td-dark">
                        <SqlCodeWithCopy
                          text={
                            'INSERT INTO RESULT \nSELECT TABLE cars_table.cars_name\n, car_speeds.speed \nFROM cars_table \nOUTER JOIN car_speeds\nON car_speeds.speed > 100'
                          }
                          type={'td'}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        Logical
                      </td>
                      <td className="align-middle">
                        customers._key = order.user_id AND len(customers.name)
                        &gt; 10 <br /> <br /> len(customers.name) &gt; 10 AND
                        substr(customer.name,1) = &ldquo;J&rdquo;
                        <br /> <br /> substr(customers.name, 5) = order.item AND
                        len(customer.name) &gt; 10 OR customer_key &gt; 1
                      </td>
                      <td className="bg-sql-td-dark">
                        <SqlCodeWithCopy
                          text={
                            'INSERT INTO RESULT \nSELECT TABLE customersStream.name\n, customersTable.country\n, ordersStream.customerId\n, FROM ordersStream \nOUTER JOIN customersStream\nON customersTable.country = "USA"\nAND customersTable.id =\nordersStream.customerId'
                          }
                          type={'td'}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h3 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Join Compatibility
                </h3>

                <div className="d-flex flex-wrap justify-content-center">
                  <div className="col-auto">
                    <table className="table table-responsive sql-cheatsheet-table bordered-td">
                      <thead>
                        <tr>
                          <td>Left</td>
                          <td>Right</td>
                          <td>Allowed types</td>
                          <td>Window</td>
                          <td>Result</td>
                        </tr>
                      </thead>
                      <tbody className="border-pricing-table">
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Stream
                          </td>
                          <td className="align-middle">Stream</td>
                          <td className="align-middle">All</td>
                          <td className="align-middle">Required</td>
                          <td className="align-middle">Stream</td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Table
                          </td>
                          <td className="align-middle">Table</td>
                          <td className="align-middle">All</td>
                          <td className="align-middle">No</td>
                          <td className="align-middle">Table</td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Table
                          </td>
                          <td className="align-middle">Stream</td>
                          <td className="align-middle">RIGHT JOIN</td>
                          <td className="align-middle">No</td>
                          <td className="align-middle">Stream</td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            Stream
                          </td>
                          <td className="align-middle">Table</td>
                          <td className="align-middle">INNER, LEFT JOIN</td>
                          <td className="align-middle">No</td>
                          <td className="align-middle">Stream</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <h3 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Stream-to-Stream Windowing with WITHIN
                </h3>

                <p>
                  When two streams are joined Lenses needs to know how far away
                  in the past and in the future to look for a matching record.
                </p>

                <SqlCodeWithCopy
                  text={
                    '\nSELECT STREAM\n\tcustomers.name\n\t, orders.item\nFROM\n\tcustomers LEFT JOIN orders WITHIN 5s\n\t\tON customers.id = orders.customer_id\nWITHIN 5s;\n'
                  }
                />

                <p className="mt-4">
                  The above example means that an event with an{' '}
                  <code>orders.item</code> may be generated with a null value
                  for <code>customers.name</code> if a matching{' '}
                  <code>customers.id</code> event has not been generated within
                  a 5 second +/- window.
                </p>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Time Windows
                </h2>

                <h3 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Supported time descriptors
                </h3>

                <div className="d-flex flex-wrap justify-content-center">
                  <div className="col-auto">
                    <table className="table table-responsive sql-cheatsheet-table bordered-td">
                      <thead>
                        <tr>
                          <td>Duration</td>
                          <td>Description</td>
                          <td>Example</td>
                        </tr>
                      </thead>
                      <tbody className="border-pricing-table">
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            ms
                          </td>
                          <td className="align-middle">time in milliseconds</td>
                          <td className="align-middle">100ms</td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            s
                          </td>
                          <td className="align-middle">time in seconds</td>
                          <td className="align-middle">10s</td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            m
                          </td>
                          <td className="align-middle">time in minutes</td>
                          <td className="align-middle">10m</td>
                        </tr>
                        <tr>
                          <td className="align-middle text-uppercase fw-600">
                            h
                          </td>
                          <td className="align-middle">time in hours</td>
                          <td className="align-middle">10h</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <h3 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Windowing types
                </h3>

                <table className="table table-responsive sql-cheatsheet-table">
                  <tbody className="border-pricing-table bordered-td">
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        Hopping Window
                      </td>
                      <td className="align-middle">
                        WINDOW BY HOP &lt;duration_time&gt;,&lt;hop_i
                        nterval&gt; <br /> <br />
                        Fixed size and overlapping windows. The same event can
                        overlap into multiple windows.
                      </td>
                      <td className="align-middle cheatsheet-td-image">
                        <StaticImage
                          className="img-fluid w-100"
                          src="../../resources/images/sql_cheatsheet/time-windows-01.svg"
                          placeholder="tracedSVG"
                          alt="WINDOW BY HOP"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        Tumbling window
                      </td>
                      <td className="align-middle">
                        WINDOW BY TUMBLE &lt;duration_time&gt;
                        <br /> <br />
                        Duration and hop interval are equal. An event can only
                        appear in one window.
                      </td>
                      <td className="align-middle cheatsheet-td-image">
                        <StaticImage
                          className="img-fluid"
                          src="../../resources/images/sql_cheatsheet/time-windows-02.svg"
                          placeholder="tracedSVG"
                          alt="WINDOW BY TUMBLE"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        Session window
                      </td>
                      <td className="align-middle">
                        WINDOW BY SESSION &lt;inactivity_interval&gt;
                        <br /> <br />
                        Defined by a period of activity separated by a specified
                        gap of inactivity at which point current session closes.
                      </td>
                      <td className="align-middle cheatsheet-td-image">
                        <StaticImage
                          className="img-fluid"
                          src="../../resources/images/sql_cheatsheet/time-windows-03.svg"
                          placeholder="tracedSVG"
                          alt="WINDOW BY Session"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        Grace period
                      </td>
                      <td className="align-middle">
                        WINDOW BY &lt;windowing type&gt; GRACE BY &lt;grace
                        time&gt;
                        <br /> <br />
                        For a window to accept late-arriving records a grace
                        period can be defined. If a record falls within a window
                        and it arrived before the end of the grace time then the
                        record will be processed and the aggregations or joins
                        will update. If not, the record is discarded.
                      </td>
                      <td className="align-middle cheatsheet-td-image">
                        <StaticImage
                          className="img-fluid"
                          src="../../resources/images/sql_cheatsheet/time-windows-04.svg"
                          placeholder="tracedSVG"
                          alt="WINDOW BY TUMBLE"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  WITH for better SQL structure
                </h2>

                <p>Use `WITH` to break the code in reusable blocks:</p>

                <SqlCodeWithCopy
                  text={
                    '\nWITH countriesStream AS (\n\tSELECT STREAM *\n\tFROM countries\n);\n'
                  }
                />
                <div className="my-4"></div>

                <SqlCodeWithCopy
                  text={
                    '\nWITH merchantsStream AS (\n\tSELECT STREAM *\n\tFROM merchants\n);\n'
                  }
                />

                <div className="my-4"></div>

                <SqlCodeWithCopy
                  text={
                    "\nWITH merchantsWithCountryInfoStream AS (\n\tSELECT STREAM\n\t\tm._key AS l_key\n\t\t, CONCAT(surname, ', ', name) AS fullname\n\t\t, address.country\n\t\t, language\n\t\t, platform\n\tFROM merchantsStream AS m JOIN countriesStream AS c\n\t\t\tON m.address.country = c._key\n\tWITHIN 1h\n);\n"
                  }
                />

                <div className="my-4"></div>
                <SqlCodeWithCopy
                  text={
                    '\nWITH merchantsCorrectKey AS (\n\tSELECT STREAM\n\t\tl_key AS _key\n\t\t, fullname\n\t\t, country\n\t\t, language\n\t\t, platform\n\tFROM merchantsWithCountryInfoStream \n);\n'
                  }
                />

                <div className="my-4"></div>

                <SqlCodeWithCopy
                  text={
                    '\nINSERT INTO currentMerchants\nSELECT STREAM *\nFROM merchantsCorrectKey;\n'
                  }
                />

                <div className="my-4"></div>
                <SqlCodeWithCopy
                  text={
                    '\nINSERT INTO merchantsPerPlatform\nSELECT TABLE\n\tCOUNT(*) AS merchants\nFROM merchantsCorrectKey\nGROUP BY platform;\n'
                  }
                />

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Functions
                </h2>

                <table className="table table-responsive sql-cheatsheet-table bordered-td">
                  <thead>
                    <tr>
                      <td>Function</td>
                      <td>Description</td>
                    </tr>
                  </thead>
                  <tbody className="border-pricing-table">
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">AS_NON_NULLABLE </span> (expr)
                      </td>
                      <td className="align-middle">
                        Returns the provided value with its type changed from
                        the original type to its non nullable version
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">AS_NULLABLE</span> (expr)
                      </td>
                      <td className="align-middle">
                        Returns the provided value with its type changed from
                        the original type to its nullable version
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">CAST</span> (dt AS int)
                      </td>
                      <td className="align-middle">
                        Enables conversion of values from one data type to
                        another
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">COALESCE</span> (value,
                        prevValue)
                      </td>
                      <td className="align-middle">
                        Returns the first non-null expression in the expression
                        list
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">DUMP</span> (expr)
                      </td>
                      <td className="align-middle">
                        Show the internal representation of a value
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">EXISTS</span> (field)
                      </td>
                      <td className="align-middle">
                        Returns true if the given field is present false
                        otherwise
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">ISNOTNULL</span> (expr)
                      </td>
                      <td className="align-middle">
                        Returns true if the input is not null; false otherwise
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">ISNULL</span> (expr)
                      </td>
                      <td className="align-middle">
                        Returns true if the input is null; false otherwise
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">SIZEOF</span> (expr)
                      </td>
                      <td className="align-middle">
                        Returns the number of elements in an array
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">TYPEOF</span> ()
                      </td>
                      <td className="align-middle">
                        Returns the object type of a complex expression. This
                        can only be used when the format on the wire includes
                        the details of the objects full name
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  String functions
                </h2>

                <table className="table table-responsive sql-cheatsheet-table bordered-td">
                  <thead>
                    <tr>
                      <td>Function</td>
                      <td>Description</td>
                    </tr>
                  </thead>
                  <tbody className="border-pricing-table">
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">ABBREVIATE</span> (expr,
                        lengthExpr)
                      </td>
                      <td className="align-middle">
                        Abbreviates the expression to the given length and
                        appends ellipses
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">BASE64</span> (expr)
                      </td>
                      <td className="align-middle">
                        Returns the input string using base64 algorithm
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">CAPITALIZE</span> (expr)
                      </td>
                      <td className="align-middle">
                        Capitalizes the first letter of the expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">CENTER</span> (target, size,
                        padExpr)
                      </td>
                      <td className="align-middle">
                        Centers a String in a larger String of size N
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">CHOP</span> (expr)
                      </td>
                      <td className="align-middle">
                        Returns the last character from an expression of type
                        string
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">CONCATENATE</span> (expr1,
                        expr2, expr3)
                      </td>
                      <td className="align-middle">
                        Returns the string representation of concatenating each
                        expression in the list. Null fields are left out
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">CONCAT</span> (expr1, expr2,
                        expr3)
                      </td>
                      <td className="align-middle">Alias for CONCATENATE</td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">CONTAINS</span> (sourceExpr,
                        targetExpr)
                      </td>
                      <td className="align-middle">
                        Returns true if an expression contains the given
                        substring
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">DECODE64</span> (expr)
                      </td>
                      <td className="align-middle">
                        Decodes a Base64 encrypted string
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">DELETEWHITESPACE</span> (expr)
                      </td>
                      <td className="align-middle">
                        Removes all whitespace from an expression of type string
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">DIGITS</span> (expr)
                      </td>
                      <td className="align-middle">
                        Retains only the digits from a string expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">DROPLEFT</span> (expr,
                        lengthExpr)
                      </td>
                      <td className="align-middle">
                        Removes the left most ‘length’ characters from a string
                        expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">DROPRIGHT</span> (expr,
                        lengthExpr)
                      </td>
                      <td className="align-middle">
                        Removes the right most ‘length’ characters from a string
                        expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">ENDSWITH</span> (sourceExpr,
                        targetExpr)
                      </td>
                      <td className="align-middle">
                        Returns true if an expression ends with the given
                        substring
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">INDEXOF</span> (expr,
                        substringExpr)
                      </td>
                      <td className="align-middle">
                        Returns the index of a substring in an expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">LENGTH</span> (expr)
                      </td>
                      <td className="align-middle">
                        Returns the length of a string. Calculates length using
                        characters as defined by UTF-16
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">LEN</span> (expr)
                      </td>
                      <td className="align-middle">Alias for LENGTH</td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">LOWERCASE</span> (strExpr)
                      </td>
                      <td className="align-middle">
                        Returns the expression in lowercase
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">LOWER</span> (strExpr)
                      </td>
                      <td className="align-middle">Alias for LOWERCASE</td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">LEFTPAD</span> (strExpr,
                        lengthExpr, padExpr)
                      </td>
                      <td className="align-middle">
                        Prepends the value of padExpr to the value of strExpr
                        until the total length is lengthExpr
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">LPAD</span> (strExpr,
                        lengthExpr, padExpr)
                      </td>
                      <td className="align-middle">Alias for LEFTPAD</td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">REGEXP</span> (strExpr,
                        regexExpr)
                      </td>
                      <td className="align-middle">
                        Returns the matched groups otherwise null
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">REGEX</span> (strExpr,
                        regexExpr)
                      </td>
                      <td className="align-middle">Alias for REGEXP</td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">REPLACE</span> (sourceExpr,
                        targetExpr, replaceExpr)
                      </td>
                      <td className="align-middle">
                        Returns a new string in which all occurrences of a
                        specified String in the current string are replaced with
                        another specified String
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">REVERSE</span> (expr)
                      </td>
                      <td className="align-middle">
                        Reverses the order of the elements in the input
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">RIGHTPAD</span> (strExpr,
                        lengthExpr, padExpr)
                      </td>
                      <td className="align-middle">
                        Appends the value of padExpr to the value of strExpr
                        until the total length is lengthExpr
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">RPAD</span> (strExpr,
                        lengthExpr, padExpr)
                      </td>
                      <td className="align-middle">Alias for RIGHTPAD</td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">STARTSWITH</span> (exprSource,
                        exprTarget)
                      </td>
                      <td className="align-middle">
                        Returns true if an expression starts with the given
                        substring
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">STRIPACCENTS</span> (expr)
                      </td>
                      <td className="align-middle">
                        Removes diacritics (approximately the same as accents)
                        from an expression. The case will not be altered
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">SUBSTRING</span> (expr,
                        startIndexExpr, endIndexExpr)
                      </td>
                      <td className="align-middle">
                        Returns a new string that is a substring of this string
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">SUBSTR</span> (expr,
                        startIndexExpr)
                      </td>
                      <td className="align-middle">Alias for SUBSTRING</td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">SWAPCASE</span> (expr)
                      </td>
                      <td className="align-middle">
                        Swaps the case of a string expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">TAKELEFT</span> (expr,
                        lengthExpr)
                      </td>
                      <td className="align-middle">
                        Returns the left most ‘length’ characters from a string
                        expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">TAKERIGHT</span> (expr,
                        lengthExpr)
                      </td>
                      <td className="align-middle">
                        Returns the right most ‘length’ characters from a string
                        expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">TRIM</span> (expr)
                      </td>
                      <td className="align-middle">
                        Removes leading and trailing spaces from the input
                        expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">TRUNCATE</span> (strExpr,
                        nExpr)
                      </td>
                      <td className="align-middle">
                        Truncates a string so that it has at most N characters
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">UNCAPITALIZE</span> (expr)
                      </td>
                      <td className="align-middle">
                        Changes the first letter of each word in the expression
                        to lowercase
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">UPPER</span> (strExpr) <br />
                        <span className="fw-600">UPPERCASE</span> (strExpr)
                      </td>
                      <td className="align-middle">
                        Returns the expression in uppercase
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">UUID</span> ()
                      </td>
                      <td className="align-middle">
                        Returns an universally unique identifier
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Obfuscation functions
                </h2>

                <table className="table table-responsive sql-cheatsheet-table bordered-td">
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Description</td>
                    </tr>
                  </thead>
                  <tbody className="border-pricing-table">
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">ANONYMIZE</span> (strExpr)
                      </td>
                      <td className="align-middle">
                        Obfuscates the entire string input
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">MASK</span> (strExpr)
                      </td>
                      <td className="align-middle">Alias for ANONYMIZE</td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">EMAIL</span> (emailExpr)
                      </td>
                      <td className="align-middle">
                        Anonymize the value and obfuscates an email address
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">FIRST1</span> (strExpr)
                      </td>
                      <td className="align-middle">
                        Anonymize the value and only keeps the first character
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">FIRST2</span> (strExpr)
                      </td>
                      <td className="align-middle">
                        Anonymize the value and only keeps the first two
                        characters
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">FIRST3</span> (strExpr)
                      </td>
                      <td className="align-middle">
                        Anonymize the value and only keeps the first three
                        characters
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">FIRST4</span> (strExpr)
                      </td>
                      <td className="align-middle">
                        Anonymize the value and only keeps the first four
                        characters
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">LAST1</span> (strExpr)
                      </td>
                      <td className="align-middle">
                        Anonymize the value and only keeps the last character
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">LAST2</span> (strExpr)
                      </td>
                      <td className="align-middle">
                        Anonymize the value and only keeps the last two
                        characters
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">LAST3</span> (strExpr)
                      </td>
                      <td className="align-middle">
                        Anonymize the value and only keeps the last three
                        characters
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">LAST4</span> (strExpr)
                      </td>
                      <td className="align-middle">
                        Anonymize the value and only keeps the last four
                        characters
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle ">
                        <span className="fw-600">INITIALS</span> (strExpr)
                      </td>
                      <td className="align-middle">
                        Anonymize the value and only keeps the initials of all
                        the words in the input
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Numeric Functions
                </h2>

                <table className="table table-responsive sql-cheatsheet-table bordered-td">
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Description</td>
                    </tr>
                  </thead>
                  <tbody className="border-pricing-table">
                    <tr>
                      <td className="align-middle text-uppercase fw-600">%</td>
                      <td className="align-middle">
                        The remainder operator (%) computes the remainder after
                        dividing its first operand by its second i.e. numExpr %
                        numExpr
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">/</td>
                      <td className="align-middle">
                        Divides one number by another (an arithmetic operator)
                        i.e. numExpr / numExpr
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">-</td>
                      <td className="align-middle">
                        Subtracts one number from another (an arithmetic
                        operator) i.e. numExpr - numExpr
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">*</td>
                      <td className="align-middle">
                        Multiplies one number with another (an arithmetic
                        operator) i.e. numExpr * numExpr
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">+</td>
                      <td className="align-middle">
                        Adds one number to another (an arithmetic operator) i.e.
                        numExpr + numExpr
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-uppercase fw-600">
                        - (negative)
                      </td>
                      <td className="align-middle">
                        Returns the negative of the value of a numeric
                        expression (a unary operator) i.e. -numExpr
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">ABS</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the absolute value of an expression that
                        evaluates to a number type
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">ACOS</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the trigonometric arc cosine of an expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">ASIN</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the trigonometric arc sine of an expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">ATAN</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the trigonometric arc tangent of an expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">CBRT</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the cube root of numExpr
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">CEIL</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the absolute value of an expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">COSH</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the hyperbolic cosine of an expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">COS</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the trigonometric cosine of an expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">DEGREES</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Converts the input expression to degrees
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">DISTANCE</span> (x1, y1, x2,
                        y2)
                      </td>
                      <td className="align-middle">
                        Calculates the distance between two points using the
                        haversine method
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">FLOOR</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the largest value not greater than the argument
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">MAX</span> (numExpr1, numExpr2,
                        numExpr3)
                      </td>
                      <td className="align-middle">
                        Returns the maximum element from an arbitrary number of
                        given elements
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">MIN</span> (numExpr1, numExpr2,
                        numExpr3)
                      </td>
                      <td className="align-middle">
                        Returns the minimum element from an arbitrary number of
                        given elements
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">MOD</span> (numExpr, numExpr)
                      </td>
                      <td className="align-middle">Alias for %</td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">NEG</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the negative value of an expression it has to
                        evaluate to a number type
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">POW</span> (numExpr1, numExpr2)
                      </td>
                      <td className="align-middle">
                        Returns numExp1 raised to the numExp2 power
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">RADIANS</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Converts the input expression to radians
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">RANDINT</span> ()
                      </td>
                      <td className="align-middle">Returns a random integer</td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">ROUND</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the closest integer of an expression, with ties
                        rounding towards positive infinity
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">SIGN</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns +1 if a value is positive or -1 if a value is
                        negative
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">SINH</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the hyperbolic sine of an expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">SIN</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the trigonometric sine of an expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">SQRT</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the square root of numExpr
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">TANH</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the hyperbolic tangent of an expression
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">TAN</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the trigonometric tangent of an expression
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Date Functions
                </h2>

                <table className="table table-responsive sql-cheatsheet-table bordered-td">
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Description</td>
                    </tr>
                  </thead>
                  <tbody className="border-pricing-table">
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">DATE</span> ()
                      </td>
                      <td className="align-middle">
                        Provides the current ISO date value
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">CONVERT_DATETIME</span>{' '}
                        (strExpr, fromPattern, toPattern)
                      </td>
                      <td className="align-middle">
                        Converts the string format of a date [and time] to
                        another using the pattern provided
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">CONVERTDATETIME</span>{' '}
                        (strExpr, fromPattern, toPattern)
                      </td>
                      <td className="align-middle">
                        Alias for CONVERT_DATETIME
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">DATETIME</span> ()
                      </td>
                      <td className="align-middle">
                        Provides the current ISO date and time
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">DATE_TO_STR</span> (strExpr,
                        pattern)
                      </td>
                      <td className="align-middle">
                        Converts a date time value to a string using the pattern
                        provided
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">DAY</span> (expr)
                      </td>
                      <td className="align-middle">
                        Extracts the day component of an expression that is of
                        type timestamp
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">HOUR</span> &lt;small)(expr)
                      </td>
                      <td className="align-middle">
                        Extracts the hour component of an expression that is of
                        type timestamp
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">MINUTE</span>{' '}
                        &lt;small)(dataExpr)
                      </td>
                      <td className="align-middle">
                        Extracts the minute component of an expression that is
                        of type timestamp
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">MONTH_TEXT</span>{' '}
                        &lt;small)(dataExpr)
                      </td>
                      <td className="align-middle">Returns the month name</td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">MONTH</span>
                        &lt;small)(dataExpr)
                      </td>
                      <td className="align-middle">
                        Extracts the month component of an expression that is of
                        type timestamp
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">SECOND</span>
                        &lt;small)(dataExpr)
                      </td>
                      <td className="align-middle">
                        Extracts the second component of an expression that is
                        of type timestamp
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">TOMORROW</span> ()
                      </td>
                      <td className="align-middle">
                        Returns the current date time plus 1 day
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">TO_DATETIME</span> (strExpr,
                        pattern)
                      </td>
                      <td className="align-middle">
                        Converts a string representation of a datetime into
                        epoch value using the pattern provided
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">TO_DATE</span> (strExpr,
                        pattern)
                      </td>
                      <td className="align-middle">
                        Converts a string representation of a date into epoch
                        value using the pattern provided
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">TO_TIMESTAMP</span> (longExpr)
                      </td>
                      <td className="align-middle">
                        Converts a long (epoch) to a date and time type
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">TO_TIMESTAMP</span> (strExpr,
                        pattern)
                      </td>
                      <td className="align-middle">
                        Converts a string using a pattern to a date and time
                        type
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">YEAR</span> (expr)
                      </td>
                      <td className="align-middle">
                        Extracts the year component of an expression that is of
                        type timestamp
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">YESTERDAY</span> ()
                      </td>
                      <td className="align-middle">
                        Returns the current date time minus 1 day
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Utility Functions
                </h2>
                <table className="table table-responsive sql-cheatsheet-table bordered-td">
                  <thead>
                    <tr>
                      <td>Name</td>
                      <td>Description</td>
                      <td>Streaming (stateless)</td>
                      <td>Streaming (stateful)</td>
                    </tr>
                  </thead>
                  <tbody className="border-pricing-table">
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">AVG</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the average of the values in a group. It ignores
                        null value. It can be used with numeric input only
                      </td>
                      <td className="align-middle">&#10004;</td>
                      <td className="align-middle">&#10004;</td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">COLLECT</span> (expr, maxN)
                      </td>
                      <td className="align-middle">
                        Returns an array in which each value in the input set is
                        assigned to an element of the array
                      </td>
                      <td className="align-middle">&#10004;</td>
                      <td className="align-middle">&#10004;</td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">COLLECT_UNIQUE</span> (expr,
                        maxN)
                      </td>
                      <td className="align-middle">
                        Returns an array of unique values in which each value in
                        the input set is assigned to an element of the array
                      </td>
                      <td className="align-middle">&#10004;</td>
                      <td className="align-middle">no</td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">COUNT</span> (*) AS total
                      </td>
                      <td className="align-middle">
                        Returns the number of records returned by a query or the
                        records in a group as a result of a GROUP BY statement
                      </td>
                      <td className="align-middle">&#10004;</td>
                      <td className="align-middle">&#10004;</td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">MAXK</span> (numExpr, N)
                      </td>
                      <td className="align-middle">
                        Returns the N largest values of an numExpr
                      </td>
                      <td className="align-middle">&#10004;</td>
                      <td className="align-middle">no</td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">MAXK_UNIQUE</span> (numExpr, N)
                      </td>
                      <td className="align-middle">
                        Returns the N largest unique values of an numExpr
                      </td>
                      <td className="align-middle">&#10004;</td>
                      <td className="align-middle">no</td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">MINK</span> (numExpr, N)
                      </td>
                      <td className="align-middle">
                        Returns the N smallest values of an numExpr
                      </td>
                      <td className="align-middle">&#10004;</td>
                      <td className="align-middle">no</td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">MINK_UNIQUE</span> (numExpr, N)
                      </td>
                      <td className="align-middle">
                        Returns the N smallest unique values of an numExpr
                      </td>
                      <td className="align-middle">&#10004;</td>
                      <td className="align-middle">no</td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <span className="fw-600">SUM</span> (numExpr)
                      </td>
                      <td className="align-middle">
                        Returns the sum of all the values, in the expression. It
                        can be used with numeric input only. Null values are
                        ignored
                      </td>
                      <td className="align-middle">&#10004;</td>
                      <td className="align-middle">&#10004;</td>
                    </tr>
                  </tbody>
                </table>

                <h2 className="pb-3 pt-5 heading-toc text-center">
                  <div className="pt-3 mobile-margin-top"></div>
                  Flags
                </h2>
                <p>
                  Allows to customize the behaviour of the application
                  (including error handling). Must be declared at the beginning
                  of the SQL statement.
                </p>

                <table className="table table-responsive sql-cheatsheet-table bordered-td">
                  <thead>
                    <tr>
                      <td>Key</td>
                      <td>Type</td>
                      <td>Description</td>
                    </tr>
                  </thead>
                  <tbody className="border-pricing-table">
                    <tr>
                      <td className="align-middle">
                        defaults.topic.autocreate
                      </td>
                      <td className="align-middle">BOOLEAN</td>
                      <td className="align-middle">
                        Creates the target topics if they do not exist
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">defaults.topic.partition</td>
                      <td className="align-middle">INT</td>
                      <td className="align-middle">
                        Controls the target topics partitions count. <br />{' '}
                        Ignored if the topic exists
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        defaults.topic.replication
                      </td>
                      <td className="align-middle">INT</td>
                      <td className="align-middle">
                        Controls the target topic replication factor. <br />{' '}
                        Ignored if the topics are present already.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        defaults.topic.key.avro.record
                      </td>
                      <td className="align-middle">STRING</td>
                      <td className="align-middle">
                        Controls the output record Key schema name.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        defaults.topic.key.avro.namespace
                      </td>
                      <td className="align-middle">STRING</td>
                      <td className="align-middle">
                        Controls the output record Key schema namespace.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        defaults.topic.value.avro.record
                      </td>
                      <td className="align-middle">STRING</td>
                      <td className="align-middle">
                        Controls the output record Key schema name.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        defaults.topic.value.avro.namespace
                      </td>
                      <td className="align-middle">STRING</td>
                      <td className="align-middle">
                        Controls the output record Key schema namespace.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        topic.`market.risk`.&lt;topic_con figuration&gt;
                      </td>
                      <td className="align-middle">STRING</td>
                      <td className="align-middle">
                        Applies the given Kafka topic setting for `market.risk`.
                        SET topic.`market.risk`.cleanup.policy
                        <br />
                        =compact,delete’’; This is applied only if the topic
                        does not exist a priori and it needs to be created
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">error.policy</td>
                      <td className="align-middle">STRING</td>
                      <td className="align-middle">
                        Controls the behaviour of handling corrupted messages.
                        Can be any of the following:
                        <ul>
                          <li>continue</li>
                          <li>fail</li>
                          <li>dlq (dead letter queue)</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">dead.letter.queue</td>
                      <td className="align-middle">STRING</td>
                      <td className="align-middle">
                        The topic to send those messages which cannot be
                        processed. Requires `error.policy` to be set to `dlq`
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">processing.guarantee</td>
                      <td className="align-middle">STRING</td>
                      <td className="align-middle">
                        The processing guarantee that should be used. Possible
                        values are AT_LEAST_ONCE (default) and EXACTLY_ONCE.
                        Exactly-once processing requires a cluster of at least
                        three brokers by default, which is the recommended
                        setting for production.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">commit.interval.ms</td>
                      <td className="align-middle">LONG</td>
                      <td className="align-middle">
                        The frequency with which to save the position of the
                        processor. If processing.guarantee is set to
                        EXACTLY_ONCE, the default value is 100, otherwise the
                        default value is 30000. This setting directly impacts
                        the behaviour of Tables, as it controls how often they
                        will emit events downstream. An event will be emitted
                        only every commit.interval.ms, so every intermediate
                        event that is received by the table will not be visible
                        downstream directly.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">poll.ms</td>
                      <td className="align-middle">LONG</td>
                      <td className="align-middle">
                        The amount of time in milliseconds to block waiting for
                        input.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        cache.max.bytes.buffering
                      </td>
                      <td className="align-middle">LONG</td>
                      <td className="align-middle">
                        Maximum number of memory bytes to be used for buffering
                        across all threads. It has to be at least 0. Default
                        value is: 10 * 1024 * 1024.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">client.id</td>
                      <td className="align-middle">STRING</td>
                      <td className="align-middle">
                        An ID prefix string used for the client IDs of internal
                        consumer, producer and restore-consumer, with pattern
                        ‘&lt;client.d&gt;-StreamThread--&lt;consumer
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">num.standby.replicas</td>
                      <td className="align-middle">INT</td>
                      <td className="align-middle">
                        The number of standby replicas for each task. Default
                        value is 0.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">num.stream.threads</td>
                      <td className="align-middle">INT</td>
                      <td className="align-middle">
                        The number of threads to execute stream processing.
                        Default value is 1.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">max.task.idle.ms</td>
                      <td className="align-middle">LONG</td>
                      <td className="align-middle">
                        Maximum amount of time a stream task will stay idle when
                        not all of its partition buffers contain records, to
                        avoid potential out-of-order record processing across
                        multiple input streams.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        buffered.records.per.partition
                      </td>
                      <td className="align-middle">INT</td>
                      <td className="align-middle">
                        Maximum number of records to buffer per partition.
                        Default is 1000.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        buffered.records.per.partition
                      </td>
                      <td className="align-middle">INT</td>
                      <td className="align-middle">
                        Maximum number of records to buffer per partition.
                        Default is 1000.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">connections.max.idle.ms</td>
                      <td className="align-middle">LONG</td>
                      <td className="align-middle">
                        Close idle connections after the number of milliseconds
                        specified by this config.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">receive.buffer.bytes</td>
                      <td className="align-middle">LONG</td>
                      <td className="align-middle">
                        The size of the TCP receive buffer (SO_RCVBUF) to use
                        when reading data. If the value is -1, the OS default
                        will be used.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">reconnect.backoff.ms</td>
                      <td className="align-middle">LONG</td>
                      <td className="align-middle">
                        The base amount of time to wait before <br /> attempting
                        to reconnect to a given host. This avoids repeatedly
                        connecting to a host in a tight loop. This backoff
                        applies to all connection attempts by the client to a
                        broker.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">reconnect.backoff.max.ms</td>
                      <td className="align-middle">LONG</td>
                      <td className="align-middle">
                        The maximum amount of time in milliseconds to wait when
                        reconnecting to a broker that has repeatedly failed to
                        connect. If provided, the backoff per host will increase
                        exponentially for each consecutive connection failure,
                        up to this maximum. <br /> After calculating the backoff
                        increase, 20% <br /> random jitter is added to avoid{' '}
                        connection <br /> storms. Default is 1000.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">retries</td>
                      <td className="align-middle">INT</td>
                      <td className="align-middle">
                        Setting a value greater than zero will cause the client
                        to resend any request that fails with a potentially
                        transient error. Default is 0
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">retry.backoff.ms</td>
                      <td className="align-middle">LONG</td>
                      <td className="align-middle">
                        The amount of time to wait before attempting to <br />{' '}
                        retry a failed request to a given topic partition.{' '}
                        <br /> This avoids repeatedly sending requests <br /> in
                        a tight loop under some failure scenarios. <br />{' '}
                        Default is 100.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">send.buffer.bytes</td>
                      <td className="align-middle">LONG</td>
                      <td className="align-middle">
                        The size of the TCP send buffer (SO_SNDBUF) <br /> to
                        use when sending data. If the value is -1, <br /> the OS
                        default will be used. <br /> Default is 128 * 1024.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">state.cleanup.delay.ms</td>
                      <td className="align-middle">LONG</td>
                      <td className="align-middle">
                        The amount of time in milliseconds to wait <br /> before
                        deleting state when a partition has migrated.
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">consumer.&lt;config&gt;</td>
                      <td className="align-middle">STRING</td>
                      <td className="align-middle">
                        Sets the underlying Kafka consumer <br /> configuration
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">producer.&lt;config&gt;</td>
                      <td className="align-middle">STRING</td>
                      <td className="align-middle">
                        Sets the underlying Kafka producer <br /> configuration
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">rocksdb.&lt;config&gt;</td>
                      <td className="align-middle">STRING</td>
                      <td className="align-middle">
                        Sets the configuration for the RocksDB <br /> database
                        when stateful processing is used
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/*  */}
              </div>
            </div>
          </div>
        </div>

        <section className="footer-cta-section mobile-margin-bottom mobile-margin-top my-5">
          <div className="container-1 bg-dark rounded-1 p-5">
            <div className="d-flex flex-wrap">
              <div className="col-md-12 col-12 text-center roll-button">
                <p className="mb-5 paragraph-title text-white">
                  Try out SQL on real-time data
                </p>
                <a className="bg-white primary-text" href="/start/">
                  <span data-hover="Get Workspace"> Get Workspace</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default sqlCheatSheet

export const pageQuery = graphql`
  query SQL_Cheat_Sheet_Query {
    allContentfulPages(
      filter: { pageReference: { pageIdentifier: { eq: "SQL_Cheatsheet" } } }
    ) {
      edges {
        node {
          title {
            title
          }
          keywords {
            keywords
          }
          description {
            description
          }
          oGimage {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
